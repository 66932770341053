<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <el-radio-group class="mb-1" v-model="textType" size="small" @change="handleTextType">
        <el-radio-button
          v-for="{paramValue,paramName} in newsType"
          :key="paramValue"
          :label="paramValue"
        >{{ paramName }}
        </el-radio-button>
        <view-search-form
          :query-info="queryInfo"
          :filter-keys="['textType']"
          class="mt-1"
          @on-search="renderTable(1)"
        >
          <el-form-item prop="indexTextFlag" label="是否在首页显示" label-width="120px">
            <el-select v-model="queryInfo.indexTextFlag" size="small" clearable style="width: 100px">
              <el-option :value="1" label="是" />
              <el-option :value="0" label="否" />
            </el-select>
          </el-form-item>
          <el-form-item prop="topFlag" label="是否轮播">
            <el-select v-model="queryInfo.topFlag" size="small" clearable style="width: 100px">
              <el-option :value="1" label="是" />
              <el-option :value="0" label="否" />
            </el-select>
          </el-form-item>
          <el-form-item prop="showFlag" label="是否显示">
            <el-select v-model="queryInfo.showFlag" size="small" clearable style="width: 100px">
              <el-option :value="1" label="是" />
              <el-option :value="0" label="否" />
            </el-select>
          </el-form-item>
        </view-search-form>
      </el-radio-group>
      <div class="header-button fr">
        <el-button v-permission="['app:news:edit']" type="primary" size="small" @click="createNews('create')">添加新闻
        </el-button>
      </div>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="title" label="新闻标题" min-width="150" />
      <el-table-column prop="previewText" label="副标题" min-width="200" />
      <el-table-column label="封面图片" width="150" align="center">
        <template v-slot="{row}">
          <el-image
            v-if="row.previewImg"
            style="width: 100%; height: auto"
            :src="$fileUrl + '' + row.previewImg"
            :preview-src-list="[$fileUrl + '' + row.previewImg]"
            fit="contain" />
          <span class="font-grey" v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="公众号链接" width="100" align="center">
        <template v-slot="{row}">
          <el-link v-if="row.mpUrl" type="primary" :href="row.mpUrl" target="_blank">点击跳转</el-link>
          <span class="font-grey" v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="tag" label="标签" width="120" align="center" />
      <el-table-column prop="viewTimes" label="阅读数" width="120" align="center" />
      <el-table-column prop="createTime" label="发布时间" width="160" align="center"/>
<!--      <el-table-column prop="sort" label="排序" width="80" align="center" />-->
      <el-table-column prop="remarks" min-width="130" label="备注" />
      <el-table-column width="130" align="center">
        <template #header>
          <tool-tips-group tips-content="轮播 与 在首页显示，只能二选一">是否轮播</tool-tips-group>
        </template>
        <template v-slot="{row}">
          <el-switch
            v-model="row.topFlag"
            @change="handleSwitch($event,'轮播', row, 'topFlag')"
            active-color="var(--color-success)"
            :active-value="1"
            active-text="是"
            :inactive-value="0"
            inactive-text="否" />
        </template>
      </el-table-column>
      <el-table-column width="150" align="center">
        <template #header>
          <tool-tips-group tips-content="在首页显示 与 轮播，只能二选一">是否在首页显示</tool-tips-group>
        </template>
        <template v-slot="{row}">
          <el-switch
            v-model="row.indexTextFlag"
            @change="handleSwitch($event,'在首页显示', row, 'indexTextFlag')"
            active-color="var(--color-success)"
            :active-value="1"
            active-text="是"
            :inactive-value="0"
            inactive-text="否" />
        </template>
      </el-table-column>
      <el-table-column label="是否显示" width="150" align="center">
        <template v-slot="{row}">
          <el-switch
            v-model="row.showFlag"
            @change="handleSwitch($event,'显示', row, 'showFlag')"
            active-color="var(--color-success)"
            :active-value="1"
            active-text="是"
            :inactive-value="0"
            inactive-text="否" />
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="{row}">
          <el-button type="primary" size="mini" v-permission="['app:news:edit']" @click="createNews('edit', row)">编辑
          </el-button>
          <el-button size="mini" type="danger" v-permission="['app:news:del']"
                     @click="del(deleteNews,row.id,`新闻-${row.title}`)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </table-view>
</template>

<script>
import { pageNewsApi, deleteNews, addNews } from '@/api/news'
import tableView from '@/vue/mixins/table-view'

export default {
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        textType: null,
        indexTextFlag: null,
        topFlag: null,
        showFlag: null
      },
      textType: null,
      newsType: []
    }
  },
  created () {
    this.getNewsType()
  },
  methods: {
    deleteNews, // 删除api
    // 获取新闻列表
    renderTable (pageNum) {
      this.queryInfo.textType = this.textType
      this.getTableData(pageNewsApi, pageNum)
    },
    // 获取新闻类型
    getNewsType () {
      this.$http.getSysDictionaryByKey('newsType').then((res) => {
        this.newsType = res.data
        if (this.newsType.length) {
          this.textType = this.newsType[0].paramValue
        }
      }).then(() => {
        this.handleTextType(this.textType)
      })
    },
    // 发布新闻
    createNews (type, item) {
      if (type === 'create') {
        this.$router.push('/appManage/publishNews')
      } else {
        this.$router.push({
          path: '/appManage/publishNews',
          query: { id: item.id }
        })
      }
    },
    /* 新闻类型
     * @param {String} val - 修改新闻类型
    * */
    handleTextType (val) {
      this.queryInfo.textType = val
      this.renderTable(1)
    },
    /*
     * 启用与禁用
     * @param {Boolean} val - 开关状态 0-关 1-启
     * @param {String} title - 提示标题
     * @param {Object} row - 当前行数据， 主要用于获取id
     * @param {String} key - 要操作的字段名
     */
    handleSwitch (val, title, row, key) {
      this.$confirm(`<p classs="confirm-message">是否确定${val ? '<b class="bg-primary">启用</b>' : '<b class="primary">关闭</b>'}${title}该新闻？</p>`, {
        dangerouslyUseHTMLString: true
      }).then(async () => {
        const _rowData = {
          id: row.id
        }
        _rowData[key] = val
        // 轮播跟在首页显示互斥
        if (key === 'indexTextFlag' && row.indexTextFlag === 1) {
          _rowData.topFlag = 0
        } else if (key === 'topFlag' && row.topFlag === 1) {
          _rowData.indexTextFlag = 0
        }
        await addNews(_rowData)
        this.$message({
          message: `设置成功，已${val ? '启用' : '关闭'}${title}！`,
          type: val ? 'success' : 'warning'
        })
       await this.renderTable()
      }).catch(() => {
        row[key] = val ? 0 : 1
        this.$message('已取消')
      })
    }
  }
}
</script>
